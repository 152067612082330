import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Box, Flex, Button, Container } from "theme-ui";
import { graphql } from "gatsby";
import { AboutMeFeatures, Banner, CopyBlock, EmailSignup, Grid, JumpToCourse, LazyImage, LinkImage, NavGrid, Pricing, Testimonial, Title, Video } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Title h1 mdxType="Title"># Stop saying "I just need an idea" and get to work</Title>
    <Container sx={{
      mt: [-0.5, -1, -1],
      mb: [4, 4, 5],
      textAlign: 'center'
    }} mdxType="Container">
      <p>{`Learn a simple framework to find and evaluate dozens of great ideas.`}</p>
      <Box sx={{
        textAlign: 'left'
      }} mdxType="Box">
        <lite-youtube {...{
          "videoid": "TsdnTwuLBkA",
          "videostartat": "0"
        }}></lite-youtube>
      </Box>
    </Container>
    <Container mdxType="Container">
      <p>{`You know what's the best way to learn a new technology?`}</p>
      <p>{`Build something.`}</p>
      <p>{`You know the best way to stand out as a job applicant?`}</p>
      <p>{`Build something.`}</p>
      <p>{`Best way to make $1000/month on the side?`}</p>
      <p>{`Build something.`}</p>
      <p>{`So why aren't you?`}</p>
      <p>{`Let me guess: `}<strong parentName="p"><em parentName="strong">{`You don't have an idea?`}</em></strong></p>
      <p>{`Or maybe you do and you're worried it's not good enough, you'll waste your time, you won't get anywhere, you won't be able to make it, who's' gonna care about your idea anyway?`}</p>
      <p>{`That's why I created `}<strong parentName="p">{`Find Your Idea`}</strong>{` – a video workshop that teaches you how to source, classify, and validate your ideas `}<em parentName="p">{`before`}</em>{` you jump in and waste your time.`}</p>
    </Container>
    <Title h1 mdxType="Title">## Find Your Idea</Title>
    <Container mdxType="Container">
      <p>{`Worried you don't have ideas? Find Your Idea teaches you how to spot tens of ideas per week.`}</p>
      <p>{`Worried you don't have good ideas? Find Your Idea gives you a quick mental checklist.`}</p>
      <p>{`Worried you'll waste your time? Find Your Idea shows you the framework I use to validate and stair-step ideas from investing 5 minutes to verify a hunch all the way to seeing if anyone will buy.`}</p>
      <p>{`Don't wanna start a business? Find Your Idea helps you classify ideas into 3 buckets based on "What's the goal of this one?"`}</p>
      <p>{`Join this class and learn every mental model and quick hack I developed over the past 10 years of making money online and studying the bootstrapping greats that came before me.`}</p>
      <p>{`You'll do exercises, answer questions, analyze other people's ideas, and contribute your own. Walk away with a solid mental checklist that helps you qualify any idea in minutes, and a work sheet you can use to develop ideas in the future.`}</p>
      <Box sx={{
        mt: 10,
        textAlign: "center"
      }} mdxType="Box">
  <a className="gumroad-button" href="https://gum.co/fKfYf" data-gumroad-single-product="true" target="_blank" rel="noopener noreferrer">
    Get Find Your Idea
  </a>
      </Box>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      